<template>
  <span></span>
</template>

<script>
import {createHistory, destroyHistory} from "@/modules/history.js";
import {isEqual, debounce} from "lodash";
export default {
  name: "NamedQuery",
  props: {
    display: {
      type: Object,
      required: true
    },
    queryName: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    return {
      ready: false
    };
  },
  computed: {
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    config() {
      if (this?.display?.namedQueries?.length && this.queryName) {
        return this?.display?.namedQueries.find(
          ({name}) => this.queryName == name
        );
      }
      return null;
    },
    interval() {
      return this.$store.getters["history/interval"] || null;
    },
    equipmentDataList() {
      return this.$store.getters["dashboard/dataList"];
    },
    namespace() {
      return this?.config?.name || "";
    },
    columns() {
      return this?.config?.dataList || [] || [];
    },
    dataIdList() {
      return this.$utils
        .distinct(this.columns || [])
        .map(({data_id}) => parseInt(data_id));
    },
    dataList() {
      return this.$utils.distinct(
        (this.equipmentDataList || []).filter(
          ({id}) => this.dataIdList.indexOf(parseInt(id)) >= 0
        )
      );
    },
    groupByExpression() {
      return this.config.groupByExpression;
    },
    history() {
      return this.$store.getters[`${this.namespace}/aggregatedEntries`] || null;
    },
    isLoaded() {
      return this.config && this.dataList.length ? true : false;
    },
    state() {
      return this.$store.getters["history/ready"];
    }
  },
  watch: {
    isLoaded: {
      handler(n, o) {
        if (!n || n == o) return;
        this.setup();
      },
      immediate: true
    },
    interval: {
      handler(n, o) {
        if ((!n && isEqual(n, o)) || !this._onFilterChange) return;
        this._onFilterChange();
      }
    }
  },
  methods: {
    onFilterChanged() {
      if (!this.ready || !this.interval) return;
      // console.log(`${this.queryName}  ${this._uid}`);
      if (this.$store.hasModule(this.namespace)) {
        this.$store.dispatch(`${this.namespace}/setInterval`, this.interval);
        this.fetch();
      }
    },
    fetch() {
      if (!this.ready || this.mode == "editor" || !this.dataIdList.length)
        return;
      if (this.$store.hasModule(this.namespace)) {
        this.$store.dispatch(`${this.namespace}/fetch`, this.dataIdList);
      }
    },
    inUse() {
      return this.$utils.deepValueSearch(
        this.display.panels || [],
        "namedQuery",
        this.queryName
      );
    },
    setup() {
      if (this.ready || !this.inUse()) return;
      createHistory(this.$store, this.namespace);
      // console.log(this.config);
      this.$store.dispatch(`${this.namespace}/setNamedQuery`, this.config);
      this.ready = true;
    }
  },
  beforeDestroy() {
    this.$root.$off("namedQuery:applied");
    destroyHistory(this.$store, this.namespace);
  },
  created() {
    this.$root.$on("namedQuery:applied", (namedQuery) => {
      if (!namedQuery || namedQuery != this.namespace || this.ready) return;
      this.setup();
    });
  },
  beforeCreate() {
    this._onFilterChange = debounce(() => {
      this.onFilterChanged();
    }, 500);
  }
};
</script>
